<template>
  <span v-if="false" />
</template>

<script>
import { capitalizeAll } from '~/helpers';

export default {
  props: {
    title: {
      type: String,
      default() {
        return this.$t('seo.defaultTitle');
      }
    },
    description: {
      type: String,
      default() {
        return this.$t('seo.defaultDescription');
      }
    },
    keywords: {
      type: String,
      default() {
        return this.$t('seo.defaultKeywords');
      }
    },
    ogImage: {
      type: String,
      default() {
        return this.host + '/meae-og-image.jpg';
      }
    },
    ogImageAlt: {
      type: String,
      default: "Logo Ministère de l'Europe et des Affaires étrangères"
    },
    disableKeywords: {
      type: Boolean,
      default: false
    },
    terminateWithTroov: {
      type: Boolean,
      default: true
    }
    // Blog => <meta name="google" content="notranslate" />
  },
  head() {
    const localesList = this.$i18n.locales;
    let isoLang;
    const ogAlternate = [];
    for (let i = 0; i < localesList.length; i++) {
      const str = localesList[i].iso.replace('-', '_');
      if (localesList[i].code === this.$i18n.locale) {
        isoLang = str;
      } else {
        ogAlternate.push({
          name: 'og:locale:alternate',
          hid: 'og_locale_alternate',
          property: 'og:locale:alternate',
          content: str
        });
      }
    }
    return {
      title: capitalizeAll(capitalizeAll(this.title)),
      link: [
        {
          rel: 'canonical',
          href: this.host + this.$route.path,
          hid: 'canonical'
        }
      ],
      meta: [
        { name: 'keywords', hid: 'keywords', content: this.disableKeywords ? undefined : this.keywords },
        // Social SEO
        // Open Graph
        { name: 'og:site_name', property: 'og:site_name', content: 'consulat.gouv.fr', hid: 'og_site_name' },
        { name: 'og:title', property: 'og:title', content: capitalizeAll(this.title), hid: 'og_title' },
        { name: 'og:description', property: 'og:description', content: this.description, hid: 'og_description' },
        { name: 'og:type', property: 'og:type', content: 'website', hid: 'og_type' },
        { name: 'og:locale', property: 'og:locale', content: isoLang.iso, hid: 'og_locale' },
        ...ogAlternate,
        { name: 'og:url', property: 'og:url', content: this.host + this.$route.fullPath, hid: 'og_url' },
        { name: 'og:image', property: 'og:image', content: this.ogImage, hid: 'og_image' },
        // Twitter Card
        { name: 'twitter:card', content: 'summary', hid: 'twit_card' },
        { name: 'twitter:site', content: this.host + this.$route.fullPath, hid: 'twit_site' },
        { name: 'twitter:title', content: capitalizeAll(this.title), hid: 'twit_title' },
        { name: 'twitter:description', content: this.description, hid: 'twit_description' },
        { name: 'twitter:image:src', content: this.ogImage, hid: 'twit_image_src' },
        { name: 'twitter:image', content: this.ogImage, hid: 'twit_image_src' },
        { name: 'twitter:image:alt', content: "Logo Ministère de l'Europe et des Affaires étrangères", hid: 'twit_image_src_alt' },

        // Google / Schema.org markup:
        { itemprop: 'name', hid: 'itemprop_name', content: capitalizeAll(this.title) },
        { itemprop: 'description', hid: 'itemprop_description', content: this.description },
        { itemprop: 'image', hid: 'itemprop_image', content: this.ogImage }
      ]
    };
  },
  computed: {
    host() {
      return this.$config.HOST_NUXT;
    }
  }
};
</script>
