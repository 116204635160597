import { mapActions, mapMutations } from 'vuex';

const NavbarBehaviorMixin = {
  methods: {
    ...mapActions({
      setDarkLogo: 'site/setDarkLogo',
      setLightLogo: 'site/setLightLogo'
    }),
    ...mapMutations({
      observeNavbar: 'site/ENABLE_NAVBAR_TOGGLE',
      disObserveNavbar: 'site/DISABLE_NAVBAR_TOGGLE',
      enableLogoToggle: 'site/ENABLE_LOGO_TOGGLE',
      disableLogoToggle: 'site/DISABLE_LOGO_TOGGLE'
    }),
    toggleIconOnScroll() {
      this.enableLogoToggle();
      this.setLightLogo();
    },
    disabledToggleIconOnScroll() {
      this.disableLogoToggle();
      this.setDarkLogo();
    }
  },
  mounted() {
    this.observeNavbar();
  },
  updated() {
    this.disObserveNavbar();
    this.observeNavbar();
  },
  beforeDestroy() {
    this.disObserveNavbar();
    this.disabledToggleIconOnScroll();
  }
};

export default NavbarBehaviorMixin;
