<template>
  <div class="page-content">
    <MetaCards
      :title="$t('common.troov_creaneau') + ' - ' + $t('seo.solution_rdv')"
      :description="$t('faq_title.ttc_description')"
      :keywords="$t('seo.ttc')"
    />
    <structured-data-webpage :name="$t('common.troov_creaneau') + ' - ' + $t('seo.solution_rdv')" :description="$t('faq_title.ttc_description')" />
    <groups-map :group="group" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NavbarBehaviorMixin from '~/components/site/navbar-behavior';
import MetaCards from '~/components/seo/meta-cards';
import StructuredDataWebpage from '~/components/seo/structured-data-web-page';
import GroupsMap from '~/components/site/groups-map';

export default {
  components: {
    GroupsMap,
    StructuredDataWebpage,
    MetaCards
  },
  // scrollToTop: true,
  mixins: [NavbarBehaviorMixin],
  layout: 'troov/meae',
  asyncData({ store, route, error, $config }) {
    const slug = $config.DEFAULT_SLUG;
    return store
      .dispatch('site/getPublicGroupBySlug', { slug })
      .then((group) => ({ group }))
      .catch(() => {
        return {
          name: '--',
          picture: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAD0lEQVR42mNkwAIYh7IgAAVVAAuInjI5AAAAAElFTkSuQmCC',
          teams: []
        };
      });
  },
  data() {
    return {
      scrollAnimations: null,
      group: {
        name: '',
        picture: '',
        teams: []
      },
      partners: []
    };
  },
  computed: {
    ...mapState({
      locale: (state) => state.lang.locale
    })
  },
  mounted() {
    this.scrollAnimations = this.$sal({
      threshold: 0.1
    });
    this.$root.context.app.isBot();
  },
  updated() {
    if (this.scrollAnimations) {
      this.scrollAnimations.reset();
    }
  },
  beforeDestroy() {
    if (this.scrollAnimations) {
      this.scrollAnimations.disable();
    }
  }
};
</script>

<style scoped>
.home-content {
  min-height: 100vh;
}
.page-content {
  margin-top: 0 !important;
}
</style>
