<template>
  <div class="container">
    <div class="row d-flex text-center mx-5 mt-1 w-60 h-100" :class="{ 'justify-content-center': group.enable_only_reservation }">
      <div v-if="group.is_public && !group.enable_only_reservation" class="col-md-6 px-md-4 shadow hover-shadow rounded">
        <NuxtLink class="h5" :to="getLostLink()">
          <b-img
            v-if="customLostFoundIllu"
            fluid
            class="service-img"
            rounded="circle"
            :src="server + '/resources/group/' + $route.params.slug + '/lost_found.jpg'"
          ></b-img>
          <b-img v-else fluid class="service-img" src="~/assets/images/SELECTION_SERVICE_1-01-min.png"></b-img>
          <p class="lead font-size-18 font-weight-bold mt-4">
            {{ $t('common.found_lost_prisederdv') }}
          </p>
        </NuxtLink>
      </div>

      <div v-if="hasReservation" class="col-md-6 px-md-4 shadow hover-shadow rounded">
        <NuxtLink
          class="h5"
          :to="localePath({ name: 'all-appointment', params: { pathMatch: group.static_slug || group.slug, lang: $i18n.locale } })"
        >
          <b-img
            v-if="customAppointementIllu"
            fluid
            class="service-img"
            rounded="circle"
            :src="server + '/resources/group/' + $route.params.slug + '/appointement.jpg'"
          ></b-img>
          <b-img v-else fluid class="service-img" src="~/assets/images/SELECTION_SERVICE_1-04-min.png"></b-img>
          <p class="lead font-size-18 font-weight-bold mt-4">
            {{ $t('common.appoitment_prisederdv') }}
          </p>
        </NuxtLink>
      </div>
    </div>
    <div class="text-center pb-5 pt-2">
      <button class="btn btn-light" @click="goBack">
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
        {{ $t('common.back_step') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectedTeam',
  props: {
    group: {
      type: Object
    },
    customLostFoundIllu: Boolean,
    customAppointementIllu: Boolean
  },
  computed: {
    server() {
      return this.$config.API_ROOT;
    },
    hasReservation() {
      return (
        this.group &&
        (this.group.enable_only_reservation ||
          (this.group.params &&
            (this.group.params.enable_reservation_shop ||
              this.group.params.enable_reservation_match ||
              this.group.params.enable_reservation_match_force)))
      );
    }
  },
  methods: {
    goBack() {
      this.$emit('back');
    },
    getLostLink() {
      const { slug = '', static_slug = null } = this.group;
      return this.localePath({ name: 'lost-team', params: { team: static_slug || slug } });
    }
  }
};
</script>
<style scoped>
.service-img {
  max-height: 220px;
}
</style>
