<template>
  <span v-if="false" />
</template>

<script>
export default {
  name: 'StructuredDataWebpage',
  props: {
    name: String,
    description: String,
    breadcrumb: [Array, String]
  },
  computed: {
    ldJson() {
      return {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: this.name || '',
        //  breadcrumb: 'Books > Literature & Fiction > Classics', << Will be enabled with better structure
        description: this.description || ''
      };
    }
  },
  created() {
    this.$store.commit('site/UPDATE_JSON_LD', { key: 'webpage', value: this.ldJson });
  },
  beforeDestroy() {
    this.$store.commit('site/UPDATE_JSON_LD', { key: 'webpage', value: null });
  }
};
</script>
