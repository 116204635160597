<template>
  <b-card id="group-card" no-body class="overflow-y-scroll" :class="{ 'vh-90': !$device.isMobile }">
    <div class="d-flex flex-column flex-md-row">
      <div class="col-md-8 col-lg-7 col-xl-6 order-md-0 col-12 order-1 p-0" :class="{ 'vh-90': !$device.isMobile }">
        <div class="d-flex flex-column h-100">
          <div class="d-flex flex-column align-items-center justify-content-center pt-4">
            <div class="pt-0 pb-5 px-5 p-md-5">
              <b-img :src="group.picture" fluid rounded class="team-logo"></b-img>
            </div>
            <h2 class="w-100 text-center">
              {{ !!selectedGroup ? selectedGroup.name : $t('common.consulat_map_title') }}
            </h2>
          </div>
          <p v-if="!!selectedGroup" class="lead text-center mt-3">
            {{ selectedGroup.address || '' }}
            <br />
            {{ selectedGroup.zipcode || '' }} {{ selectedGroup.region || '' }} {{ selectedGroup.city || '' }} - {{ selectedGroup.country || '' }}
          </p>

          <div v-if="selectedGroup && !selectedGroup.enable_only_reservation">
            <p v-if="group.slug !== 'king-jouet'" class="lead font-weight-normal text-center mt-4">
              {{ !!selectedGroup || selectedGroup.enable_only_reservation ? $t('common.selection_a_service') : $t('common.choose_partner_places') }}
            </p>
            <p v-else class="lead font-weight-normal text-center mt-4">
              {{ $t('common.selection_a_shop') }}
            </p>
          </div>
          <div v-if="!selectedGroup" class="row justify-content-center pt-3 px-2">
            <div class="col-md-8">
              <b-input v-model="search" v-b-visible="visibleHandler" size="lg" :placeholder="$t('common.search')" />
            </div>
          </div>
          <div class="dropdown-divider"></div>
          <div v-if="!!selectedGroup">
            <selected-team
              :group="selectedGroup"
              :custom-lost-found-illu="group.custom_lost_found_illustration"
              :custom-appointement-illu="group.custom_appointement_illustration"
              @back="showList"
            />
          </div>
          <div v-if="!selectedGroup" class="flex-grow-1 overflow-y-scroll px-2">
            <div class="row">
              <div v-for="(team, i) in groupFiltered.teams" :key="groupFiltered.name + team.name" class="col-sm-6 p-2">
                <div class="d-flex align-items-center shadow hover-shadow rounded px-2 py-2 cursor-click" @click="onSelectGroup(team, i)">
                  <div class="rounded-pill bg-light p-3 mr-2">
                    <i class="bx bx-map bx-sm align-middle" />
                  </div>
                  <div>
                    <a v-if="team.is_public" class="h5" href="#">
                      <i class="bx bx-link"></i>
                      {{ team.name }}
                    </a>
                    <NuxtLink
                      v-else
                      class="h5"
                      :to="localePath({ name: 'all-appointment', params: { pathMatch: team.static_slug || team.slug, lang: $i18n.locale } })"
                    >
                      <i class="bx bx-link"></i>
                      {{ team.name }}
                    </NuxtLink>
                    <p class="font-size-12">
                      {{ team.address || '' }}
                      <br />
                      {{ team.zipcode || '' }} {{ team.region || '' }} {{ team.city || '' }} - {{ team.country || '' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!$device.isMobile" class="col-md-4 col-lg-5 col-xl-6 order-md-1 col-12 order-0 p-0">
        <div class="overflow-hidden" :class="{ 'little-map': isSmallScreen, 'normal-map': !isSmallScreen }">
          <div id="map-mapbox" class="h-100 leaflet-container"></div>
        </div>
      </div>
    </div>
    <a v-if="!inputVisible && $device.isMobile" href="#top" class="btn btn-primary rounded-pill back-to-top" @click.prevent="goToTop">
      <i class="bx bx-chevrons-up bx-md align-middle"></i>
    </a>
  </b-card>
</template>

<script>
import SelectedTeam from '~/components/prisederdv/selected-team';
import { slugifyClean } from '~/helpers';

export default {
  name: 'GroupsMap',
  components: { SelectedTeam },
  props: {
    group: {
      type: Object,
      default: () => ({
        name: '',
        picture: '',
        teams: [],
        enable_nav_logo: false,
        custom_lost_found_illustration: false,
        custom_appointement_illustration: false
      })
    }
  },
  data() {
    return {
      search: '',
      isSmallScreen: false,
      selectedGroup: null,
      map: {
        info: {
          infoWindowPos: null,
          infoWinOpen: false,
          currentMidx: null,
          infoOptions: {
            content: '',
            pixelOffset: {
              width: 0,
              height: -35
            }
          }
        },
        center: { lat: 46.232192999999995, lng: 2.209666999999996 }
      },
      leaf: {
        markers: [],
        map: null
      },
      inputVisible: true
    };
  },
  computed: {
    groupFiltered() {
      if (this.search) {
        const term = slugifyClean(this.search.toLowerCase(), false).trim();
        return {
          ...this.group,
          teams: this.group.teams.filter((team) => slugifyClean(team.name.toLowerCase(), false).trim().includes(term))
        };
      }
      return this.group;
    },
    mapMarkers() {
      if (this.groupFiltered && this.groupFiltered.teams.length > 0) {
        return this.groupFiltered.teams.map((team) => {
          const linkAppointment = this.localePath({
            name: 'all-appointment',
            params: { pathMatch: team.static_slug || team.slug, lang: this.$i18n.locale }
          });
          let btnClass = 'btn btn-block btn-primary';
          if (this.group.custom_lost_found_illustration || this.group.custom_appointement_illustration) {
            btnClass = 'btn btn-block btn-dark';
          }
          let infoText = `<h6>${team.name}</h6>
<p>
  ${team.address || ''}
  <br />
  ${team.zipcode || ''} ${team.region || ''} ${team.city || ''} - ${team.country || ''}
</p>`;
          if (team.is_public && !team.enable_only_reservation) {
            const linkLostFound = this.localePath({ name: 'all', params: { pathMatch: team.static_slug || team.slug, lang: this.$i18n.locale } });
            infoText += `
<div class="dropdown-divider"></div>
<a class="${btnClass}" href="${linkLostFound}">${this.$t('common.alert_item_lost_found')}</a>`;
          }
          if (
            team.enable_only_reservation ||
            (team.params &&
              (team.params.enable_reservation_shop || team.params.enable_reservation_match || team.params.enable_reservation_match_force))
          ) {
            infoText += `
<div class="dropdown-divider"></div>
<a class="${btnClass}" href="${linkAppointment}">${this.$t('common.take_appointment')}</a>`;
          }
          return {
            infoText,
            title: team.name,
            position: {
              lng: team.loc.coordinates[0],
              lat: team.loc.coordinates[1]
            }
          };
        });
      }
      return [];
    }
  },
  watch: {
    search() {
      this.map.info = {
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        infoOptions: {
          content: '',
          pixelOffset: {
            width: 0,
            height: -35
          }
        }
      };
      if (!this.$device.isMobile) this.fitBounds();
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.adaptLayout);
      this.adaptLayout();
    });

    if (!this.$device.isMobile) {
      this.loadMap();
      this.fitBounds();
    }
  },
  beforeDestroy() {
    this.$store.commit('site/SET_CURRENT_GROUP', {});
    window.removeEventListener('resize', this.adaptLayout);
  },
  methods: {
    loadMap() {
      this.leaf.map = this.$leaflet.map('map-mapbox', {
        doubleClickZoom: false,
        boxZoom: false,
        dragging: false,
        scrollWheelZoom: false,
        tap: false,
        touchZoom: false
      });

      const tileUrl = `https://tile.jawg.io/jawg-sunny/{z}/{x}/{y}.png?access-token=${this.$config.jawg.accessToken}`;

      this.$leaflet
        .tileLayer(tileUrl, {
          attribution:
            '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank" class="jawg-attrib">&copy; <b>Jawg</b>Maps</a> | <a href="https://www.openstreetmap.org/copyright" title="OpenStreetMap is open data licensed under ODbL" target="_blank" class="osm-attrib">&copy; OSM contributors</a>',
          maxZoom: 18,
          minZoom: 1,
          tileSize: 512,
          zoomOffset: -1
        })
        .addTo(this.leaf.map);
    },
    fitBounds() {
      if (this.leaf && this.leaf.map) {
        if (this.mapMarkers.length > 0) {
          const bounds = new this.$leaflet.LatLngBounds();
          this.leaf.markers.forEach((marker) => {
            marker.remove();
          });
          this.leaf.markers = [];
          this.mapMarkers.forEach((marker) => {
            this.leaf.markers.push(
              this.$leaflet
                .marker(marker.position, {
                  title: marker.title,
                  alt: marker.title,
                  riseOnHover: true
                })
                .bindPopup(marker.infoText)
                .addTo(this.leaf.map)
            );
            bounds.extend(marker.position);
          });
          this.leaf.map.fitBounds(bounds, {
            padding: [0, 0],
            maxZoom: 16,
            minZoom: 1
          });
        }

        this.leaf.map.zoomControl.setPosition('topright');
      }
    },
    toggleInfoWindow(marker, idx) {
      this.map.info.infoWindowPos = marker.position;
      this.map.info.infoOptions.content = marker.infoText;

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.map.info.infoWinOpen = !this.map.info.infoWinOpen;
      }
      // if different marker set infowindow to open and reset current marker index
      else {
        this.map.info.infoWinOpen = true;
        this.map.info.currentMidx = idx;
      }
    },
    adaptLayout() {
      this.isSmallScreen = typeof window !== 'undefined' && window.matchMedia('(max-width: 768px)').matches;
    },
    onSelectGroup(group, i) {
      if (!this.$device.isMobile) {
        this.leaf.markers[i].togglePopup();
        this.selectedGroup = group;
      } else {
        this.selectedGroup = group;
        this.goToTop();
      }
    },
    showList() {
      this.selectedGroup = null;
      if (this.leaf.map) {
        this.leaf.map.closePopup();
      }
    },
    visibleHandler(isVisible) {
      this.inputVisible = isVisible;
    },
    goToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
.overflow-y-scroll {
  overflow-y: scroll;
}
.vh-90 {
  height: 90vh !important;
  max-height: 90vh !important;
  overflow: hidden;
}
.little-map {
  height: 260px !important;
}
.normal-map {
  height: 90vh !important;
  max-height: 90vh !important;
}
.avatar-xl.fluid-h {
  height: auto !important;
  max-width: 7.5rem;
}

.team-logo {
  width: auto !important;
  max-height: 150px;
}
.back-to-top {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
</style>
